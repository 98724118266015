/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/scss";
@import "~@ionic/angular/css/ionic-swiper";

@font-face {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/font/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-Bold";
  font-style: bolder;
  font-weight: bolder;
  src: url("./assets/font/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  font-style: bolder;
  font-weight: bolder;
  src: url("./assets/font/OpenSans-ExtraBold.ttf");
}

:root {
  --ion-font-family: OpenSans-Regular;
}

.table-body {
  font-size: 12px;
  text-align: center;
  border: 1px solid rgb(234, 234, 234);
  border-collapse: collapse;
  td {
    padding: 5px;
    border-left: 1px solid rgb(234, 234, 234);
    border-collapse: collapse;
  }
  tr:hover td {
    background: rgb(234, 234, 234);
  }
  tr:nth-child(5n) {
    border-bottom: 1px solid rgb(234, 234, 234);
    border-collapse: collapse;
  }
  tr:first-child > td,
  tr:nth-child(5n + 6) > td {
    border: 1px solid rgb(234, 234, 234);
    border-collapse: collapse;
  }
}

.welcome-poupup {
  --width: min(90%, 600px);
  --height: 450px;
}

.popover-viewport {
  overflow: hidden;
}

.paywall-modal {
  --width: 100%;
  --height: 50vh;
  --max-height: 360px;
  --backdrop-opacity: 0.9;
  align-items: flex-end;
}

@media (max-width: 630px) {
  .paywall-modal {
    --height: 80vh;
    --max-height: 80vh;
  }
}

ion-popover.ios::part(content) {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
